import React from 'react';

import { NextPageWithLayout } from 'next';

import { ShopLandingFeature } from './components/ShopLandingFeature';
import { ShopLandingHeader } from './components/ShopLandingHeader';
import { ShopLandingQuestion } from './components/ShopLandingQuestions';

export interface IShopLandingPage {
  appPage?: boolean;
}

export const ShopLandingPage: NextPageWithLayout<IShopLandingPage> = ({ appPage = false }) => (
  <div>
    <ShopLandingHeader appPage={appPage} />
    {!appPage && <ShopLandingFeature />}
    <ShopLandingQuestion />
  </div>
);
